<template>
  <div>
    <div class="top-content">
      <v-select
        style="width:120px"
        v-model="LangId"
        label="LangCode"
        :options="listLanguages"
        :reduce="x => x.LangId"
        :clearable="true"
        placeholder="Ngôn ngữ"
        />
      <b-button
        variant="primary"
        style="margin-left: 20px"
        @click="exportPDF"
        >{{ $t("golf_common_export_to_pdf") }}</b-button
      >
      <b-button 
        variant="primary" 
        style="margin-left: 20px"
        @click="openSidebarSendEmail"
      >
        {{ $t("golf_confirm_email_booking") }}
      </b-button
      >
    </div>
    <div v-if="type == 'BOOKED'" style="display: flex; justify-content: center">
      <VueHtml2pdf
        :show-layout="true"
        :enable-download="true"
        :manual-pagination="true"
        ref="html2Pdf"
        filename="ConfirmBooking"
      >
        <section slot="pdf-content">
          <div v-html="keyValue"></div>
        </section>
      </VueHtml2pdf>
    </div>
    <div v-if="type == 'CANCEL'" style="display: flex; justify-content: center">
      <VueHtml2pdf
        :show-layout="true"
        :enable-download="true"
        :manual-pagination="true"
        ref="html2Pdf"
        filename="CancelBooking"
      >
        <section slot="pdf-content">
          <div v-html="keyValue"></div>
        </section>
      </VueHtml2pdf>
    </div>
    <SendEmail
      ref="SendEmail"
      :data="BookingDetail" 
      :bodyEmail="htmlEmail"
      :type="this.type"
    />
  </div>
</template>
<script>
import { commonServices } from "@/api/common-services";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { booking } from "@/api/booking";
import vSelect from 'vue-select'
import SendEmail from '@/views/modules/booking/Email/SendEmail'
export default {
  name: "confirmEmail",
  components: {
    SendEmail
  },
  data() {
    return {
      filename: 'abc',
      bookingDetailId: null,
      keyValue: null,
      show: true,
      BookingDetail: null,
      Booking: null,
      BookingDetailCourse: null,
      type: null,
      htmlEmail: null,
      listLanguages: [],
      LangId: 1000000,
      HtmlEng: null,
      HtmlVie:null,
    };
  },
  created() {
    html2canvas.useCORS = true;
    this.api_RBK02B(this.LangId);
    this.getListLanguages()
    this.call_api_RCOM01_getHtmlEng()
  },
  watch: {
    LangId() {
      this.replaceEmail()
    }
  },
  methods: {
    async api_RBK02B(LangId) {
      this.show = false;
      this.bookingDetailId = this.$route.params.id * 1;
      this.type = this.$route.params.type
      if(this.type == 'BOOKED') {
        var body = {
        BookingDetail: {
          Id: this.bookingDetailId,
        },
      };
      } else {
        var body = {
        BookingDetail: {
          Id: this.bookingDetailId,
        },
        Type: "CANCELLED"
      };
      }
      await booking.getDetailBooking2B(body).then((response) => {
        this.BookingDetail = response.Data.Booking.BookingDetail;
        this.BookingDetail.OpenDate = this.convertUTC(
          this.BookingDetail.OpenDate,
          "DD/MM/YYYY HH:mm"
        );
         this.BookingDetail.TotalPrice = this.formatPrice(this.BookingDetail.TotalPrice)
        this.Booking = response.Data.Booking;
        this.BookingDetailCourse = this.BookingDetail.BookingDetailCourse
        // console.log(this.BookingDetailCourse)
        return this.api_RCOM01(LangId);
      });
    },
    async api_RCOM01(LangId) {
      const body = {
        KeyGroup: "EMAIL_CONFIRMATION",
        LangOfData: String(LangId),
      };
      await commonServices.getCommon(body).then((response) => {
        this.BookingDetail.Url = response.Data.find(x => x.KeyCode == "URL_IMAGE").KeyValue
        for(let i=0; i < response.Data.length; i++) {
          if(response.Data[i].KeyCode == this.type){
            this.HtmlVie = response.Data[i].KeyValue;
            var ob = response.Data[i].KeyValue;
            break;
          }
        }
        for (const key in this.BookingDetail) {
          if (Object.hasOwnProperty.call(this.BookingDetail, key)) {
            const element = this.BookingDetail[key];
            var exp = new RegExp(`{{ ${key} }}`, "g");
            ob = ob.replace(exp, element);
          }
        }
        for (const key in this.Booking) {
          if (Object.hasOwnProperty.call(this.Booking, key)) {
            const element = this.Booking[key];
            var exp = new RegExp(`{{ ${key} }}`, "g");
            ob = ob.replace(exp, element);
          }
        }
        this.keyValue = ob;
        if(this.type == 'BOOKED'){
          this.show = true;
          setTimeout(() => {
          const TotalNumberOfHole = document.getElementById("TotalNumberOfHole");
          for ( let i = 0 ; i < this.BookingDetailCourse.length; i++ ){
            const span = document.createElement('span')
            span.innerHTML= `${this.BookingDetailCourse[i].Course.CourseName}-${this.BookingDetailCourse[i].NumberOfHole} `
            TotalNumberOfHole.append(span)
          }
        }, 1000);
        }
        if(this.type == 'CANCEL') {
          setTimeout( () => {
            const TotalNumberOfHole = document.getElementById("TotalNumberOfHole");
          for ( let i = 0 ; i < this.BookingDetailCourse.length; i++ ){
            const span = document.createElement('span')
            span.innerHTML= `${this.BookingDetailCourse[i].Course.CourseName}-${this.BookingDetailCourse[i].NumberOfHole} `
            TotalNumberOfHole.append(span)
          }
          },1000)
        }
        setTimeout( () => {
        this.htmlEmail = this.keyValue
        this.htmlEmail =  this.htmlEmail.replace('<td id="TotalNumberOfHole" style="width:70%;border:1px solid;"></td>',String(document.getElementById("TotalNumberOfHole").outerHTML))
        },1000 )
      });
    },
    exportPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    openSidebarSendEmail() {
      this.$refs.SendEmail.open()
    },
    async getListLanguages() {
      await commonServices.getLanguages()
        .then(res => {
          if (res) {
            this.listLanguages = res.Data.LangCategory.filter(x => x.Active == true)
          }
        })
    },
    async call_api_RCOM01_getHtmlEng() {
      const body = {
        KeyGroup: "EMAIL_CONFIRMATION",
        LangOfData: "1000001"
      };
      await commonServices.getCommon(body).then(res => { 
        for(let i=0; i < res.Data.length; i++) {
          if(res.Data[i].KeyCode == this.type){
            this.HtmlEng = res.Data[i].KeyValue;
            break;
          }
        }
      })},
    replaceEmail() {
      if(this.LangId == 1000000){
        var ob = this.HtmlVie
      } else {
        var ob = this.HtmlEng
      }
      for (const key in this.BookingDetail) {
          if (Object.hasOwnProperty.call(this.BookingDetail, key)) {
            const element = this.BookingDetail[key];
            var exp = new RegExp(`{{ ${key} }}`, "g");
            ob = ob.replace(exp, element);
          }
        }
        for (const key in this.Booking) {
          if (Object.hasOwnProperty.call(this.Booking, key)) {
            const element = this.Booking[key];
            var exp = new RegExp(`{{ ${key} }}`, "g");
            ob = ob.replace(exp, element);
          }
        }
        this.keyValue = ob;
        if(this.type == 'BOOKED'){
          this.show = true;
          setTimeout(() => {
          const TotalNumberOfHole = document.getElementById("TotalNumberOfHole");
          for ( let i = 0 ; i < this.BookingDetailCourse.length; i++ ){
            const span = document.createElement('span')
            span.innerHTML= `${this.BookingDetailCourse[i].Course.CourseName}-${this.BookingDetailCourse[i].NumberOfHole} `
            TotalNumberOfHole.append(span)
          }
        }, 1000);
        }
        if(this.type == 'CANCEL') {
          setTimeout( () => {
            const TotalNumberOfHole = document.getElementById("TotalNumberOfHole");
          for ( let i = 0 ; i < this.BookingDetailCourse.length; i++ ){
            const span = document.createElement('span')
            span.innerHTML= `${this.BookingDetailCourse[i].Course.CourseName}-${this.BookingDetailCourse[i].NumberOfHole} `
            TotalNumberOfHole.append(span)
          }
          },1000)
        }
        setTimeout( () => {
        this.htmlEmail = this.keyValue
        this.htmlEmail = this.htmlEmail.replace('<td id="TotalNumberOfHole" style="width:70%;border:1px solid;"></td>', String(document.getElementById("TotalNumberOfHole").outerHTML))
        },1000 )
    }
  },
};
</script>
<style lang="scss">
.top-content {
  display: flex;
  justify-content: flex-end;
}
#confirmEmail {
  height: 297mm;
  width: 210mm;
}
.vue-html2pdf .layout-container {
  display: contents !important;
}
</style>